<template>
    <div class="w-full p-6 bg-white rounded-lg">
        <div>
            <LabelForm>{{ $t("resorts.thrills.key") }} *</LabelForm>
            <vs-input class="w-full border-grey-light" name="key" v-model="thrill.key" v-validate="'required'" />
            <span class="text-sm text-danger">{{ errors.first('key') }}</span>
        </div>

        <LocalizedContent v-model="localizedContent" :fieldsDefinition="[
                { name: 'name', label: $t('resorts.thrills.name'), type: 'text' },
            ]" 
        />

        <div class="mt-10 text-right">
            <button class="inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary" @click.stop="handleSave">
                {{ $t("shops.save") }}
            </button>
            <router-link class="inline-block mb-2 mr-2 rounded-lg sk-btn sk-btn-primary btn-border"
                :to="{ name: 'resort-thrills' }">
                {{ $t("shops.cancel") }}
            </router-link>
        </div>
    </div>
</template>

<script>
import LocalizedContent from '@/modules/Shared/Components/form/LocalizedContent'
import LabelForm from '@/modules/Shared/Components/form/LabelForm'
import ElementService from '@/modules/Shared/Pages/sections/ElementService'
import loader from "@/modules/Shared/Mixins/loader";
import DatatableService from '@/modules/Shared/Pages/sections/DatatableService';
import { v4 as uuidv4 } from 'uuid';
import store from "@/modules/Shared/Store/store";

export default {
    name: 'EditThrill',
    mixins: [loader],
    components: {
        LabelForm,
        LocalizedContent,
    },
    data() {
        return {
            thrill: {
                uuid: uuidv4(),
                key: '',
                names: {},
                resort_uuid: this.$route.params.uuid,
                order: 0,
            },
            service: new ElementService('v4/resorts/ride-thrills'),
            tableService: new DatatableService('v4/resorts/ride-thrills', { resort_uuid: this.$route.params.uuid }),
        }
    },
    computed: {
        localizedContent: {
            get: function () {
                let languages = []
                Object.entries(this.thrill.names).forEach(([key, value]) => {
                    languages.push({
                        'language': key,
                        'name': value
                    })
                })
                return languages
            },
            set: function (newValue) {
                this.thrill.names = {};
                newValue.forEach(item => {
                    this.thrill.names[item.language] = item.name
                })
            }
        }
    },
    async beforeRouteEnter (to, from, next) {
        if (to.params.elementUuid === undefined) {
            next()
            return
        }
        const service = new ElementService('v4/resorts/ride-thrills')
        const thrill = await service.fetchElement(to.params.elementUuid)
        await store.dispatch('setRouteElement', thrill)
        next(vm => vm.thrill = thrill)
    },
    async created() {
        await this.loadAndNotifyError(async () => {
            if (this.isNewThrill()) {
                this.thrill.order = await this.getOrder()
            }
        })
    },
    methods: {
        isNewThrill() {
            return this.$route.params.elementUuid === undefined
        },
        payloadForAPI() {
            return {
                ...this.thrill,
                ...{ names: this.localizedContent }
            }
        },

        async handleSave() {
            await this.loadAndNotify(async () => {
                if (this.isNewThrill()) {
                    await this.service.createElement(this.thrill.uuid, this.payloadForAPI())
                }

                if (!this.isNewThrill()) {
                    await this.service.editElement(this.thrill.uuid, this.payloadForAPI())
                }
                this.$router.push({ name: 'resort-thrills' })
            })
        },

        async getOrder() {
            if (!this.isNewThrill()) {
                return this.thrill.order
            }

            let thrills = await this.tableService.fetchElements({}, 1, { sort_by: 'order', sort_type: 'desc' })
            if (thrills.data.length > 0) {
                return thrills.data[0].order + 1
            }

            return 0
        },
    }
}
</script>
